<template>
  <div class="mainn">
    <ReturnProcess @setSalesPerson="handleSetSalesPerson" />
    <ReturnProcessToStock :sales-user-id="salesUserId" />
  </div>
</template>

<script>
import ReturnProcess from '@/components/orders/ReturnProcess.vue'
import ReturnProcessToStock from '@/components/orders/ReturnProcessToStock.vue'

export default {
  components: {
    ReturnProcess,
    ReturnProcessToStock,
  },
  data() {
    return {
      salesUserId: '',
    };
  },
  methods: {
    handleSetSalesPerson(salesUserId) {
      this.salesUserId = salesUserId;
    },
  },
}


</script>
<style>
.mainn{
  padding: 75px 15px 15px 120px;
    display: flex;
    gap: 30px;
}

</style>
