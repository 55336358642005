<template>
  <div>
    <b-modal
      id="return-to-stock"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeToStock')"
      hide-footer
      class="custom-modal"
      @close="onCancel"
    >
      <b-form>
        <!-- <b-form-group
          id="input-select-1"
          :label="$t('SelectedArticles')"
          label-for="select-1"
        >
          <ul>
            <li
              v-for="article in articleNumber"
              :key="article"
            >
              {{ article }}
            </li>
          </ul>
        </b-form-group> -->
        <b-form-group
          style="width: 100%;"
          :label="$t('Location')"
        >
          <vue-select
            v-model="location"
            :options="getLocationsMoving"
            label="locationName"
            :reduce="(e) => e.locationId"
            :placeholder="$t('Location')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Notes')"
          style="margin-top: 20px;"
        >
          <b-form-textarea
            id="textarea"
            v-model="notes"
            :placeholder="$t('Reason')"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    articleNumber: {
      type: Array,
    },
    salesUserId: {
      type: String,
    },
  },
  data() {
    return {
      location: null,
      notes: null,
    }
  },

  computed: {
    ...mapGetters(['getLocationsMoving']),

  },
  mounted() {
    this.movingLocations()
  },
  methods: {
    ...mapActions(['movingLocations', 'returnOrder']),
    onSubmit() {
      const object = {
        salesUserId: this.salesUserId,
        orderItemIds: this.articleNumber, // Assuming `articleNumber` is an array of orderItemIds
        locationId: this.location, // Location selected from vue-select
        notes: this.notes || '', // Notes entered by the user
      };
      this.returnOrder({
        object,
      });
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.notes = null;
      this.location = null;
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
